// import { useState } from "react"
import ContainerView from "../../landing-layout/components/ContainerView"
// import ModalAlert, { ModalAlertProps } from "../../../../components/interaction/ModalAlert"

const BestSellingLeadView = () => {
  // const [modalAlert, setModalAlert] = useState<ModalAlertProps>({
  //   options: {
  //     visible: false,
  //     actionClick: () => { },
  //     severity: "success"
  //   }
  // })
  return (
    <div className="text-gray-600 dark:text-gray-300" id="best-selling-leads">
      {/* <ModalAlert options={modalAlert.options} /> */}
      <ContainerView>
        <div className=" space-y-4 px-6 md:px-0">
          <h2 className="text-center text-2xl font-bold text-gray-800 dark:text-white md:text-4xl">
            OUR BEST-SELLING LEADS
          </h2>
          <p className="text-gray-600 dark:text-gray-300 text-center max-w-2xl mx-auto">
            We have a Digital Marketing team specialized in sourcing out email leads specifically for the business you intend to promote with us, we have a success rate of 97%, so you cannot go wrong with us
          </p>
          <p className="text-gray-600 dark:text-gray-300 text-center ">
            Even Better, Ready To Purchase Email Leads - 2,400 units
          </p>
        </div>
        <div className='my-8 grid grid-flow-row grid-cols-1 lg:grid-cols-2 gap-2 items-center aspect-auto p-8'>
          <div className="flex flex-col gap-3" >
            <img className='aspect-video object-cover' src={"https://firebasestorage.googleapis.com/v0/b/autopilot-mobile.appspot.com/o/assets%2Fimages%2Fproducts%2Fpro-email-package.jpg?alt=media&token=6c49d7a5-40ea-4fa5-93b3-a747d167093e"} alt="pro email" />
            <h4 className="my-2 text-sm font-bold text-gray-700 dark:text-white md:text-xl">
              Pro Email package
            </h4>



            {/* <button
              onClick={() => {
                setModalAlert((prev) => {
                  return {
                    options: {
                      ...prev.options,
                      visible: true,
                      message: "In order to allow efficiency of payments and avoid usual errors, we process payment strictly via our chat terminal, please click on the button below to chat with our agent and make payment, we will be with you in seconds! now",
                      severity: "info",
                      actionText: "Pay now",
                      actionClick: () => {
                        window.location.replace("https://tawk.to/chat/67202b914304e3196ad9c721/1ibaqkbsu");
                      },
                      close: () => {
                        setModalAlert({
                          options: {
                            visible: false,
                            actionClick: () => { },
                            severity: "info"
                          }
                        })
                      }
                    }
                  }
                })
              }}
              className="relative flex h-12 w-full items-center justify-center px-8 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
            >
              <span className="relative text-base font-semibold text-white dark:text-dark"
              >Get now</span
              >
            </button> */}
            <a
              href="https://wise.com/pay/r/REWeGnAWx3MrdJs" className="relative mt-10 flex h-12 w-full items-center justify-center px-8 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max">
              <span className="relative text-base font-semibold text-white dark:text-dark"
              >Get now</span
              >
            </a>
          </div>
          <div className="flex flex-col gap-3" >
            <img className='aspect-video object-cover' src={"https://firebasestorage.googleapis.com/v0/b/autopilot-mobile.appspot.com/o/assets%2Fimages%2Fproducts%2Flite-email-package.jpg?alt=media&token=db5eb0a6-aad5-4a20-bd62-3b1556883a46"} alt="pro email" />
            <h4 className="my-2 text-sm font-bold text-gray-700 dark:text-white md:text-xl">
              Lite Email Package
            </h4>



            {/* <button
              onClick={() => {
                setModalAlert((prev) => {
                  return {
                    options: {
                      ...prev.options,
                      visible: true,
                      message: "In order to allow efficiency of payments and avoid usual errors, we process payment strictly via our chat terminal, please click on the button below to chat with our agent and make payment, we will be with you in seconds! now",
                      severity: "info",
                      actionText: "Pay now",
                      actionClick: () => {
                        window.location.replace("https://tawk.to/chat/67202b914304e3196ad9c721/1ibaqkbsu");
                      },
                      close: () => {
                        setModalAlert({
                          options: {
                            visible: false,
                            actionClick: () => { },
                            severity: "info"
                          }
                        })
                      }
                    }
                  }
                })
              }}
              className="relative flex h-12 w-full items-center justify-center px-8 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
            >
              <span className="relative text-base font-semibold text-white dark:text-dark"
              >Get now</span
              >
            </button> */}
            <a
              href="https://wise.com/pay/r/maP6bn35qsxc9uo" className="relative mt-10 flex h-12 w-full items-center justify-center px-8 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max">
              <span className="relative text-base font-semibold text-white dark:text-dark"
              >Get now</span
              >
            </a>
          </div>
        </div>


      </ContainerView>
    </div>
  )
}

export default BestSellingLeadView