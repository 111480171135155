// import { useState } from "react"
import ContainerView from "../../landing-layout/components/ContainerView"
// import ModalAlert, { ModalAlertProps } from "../../../../components/interaction/ModalAlert"

const FullAutomationDetailsView = () => {
    // const [modalAlert, setModalAlert] = useState<ModalAlertProps>({
    //     options: {
    //         visible: false,
    //         actionClick: () => { },
    //         severity: "success"
    //     }
    // })
    return (
        <main
            className="space-y-40 mb-40"
        >
            {/* <ModalAlert options={modalAlert.options} /> */}
            <ContainerView>
                <div className="relative pt-36 ml-auto">
                    <div className="lg:w-2/3 text-center mx-auto mb-10">
                        <h1 className="text-gray-900 dark:text-white font-bold text-5xl md:text-6xl xl:text-7xl"> <span className="text-primary dark:text-white">Get Full Automation</span></h1>
                    </div>
                </div>


                <div className="max-w-2xl mx-auto">
                    <p className="text-gray-600 dark:text-gray-300">
                        Our full automation package is worth about $5,000 usd, check out what it offers and why digital marketers earn more than £2000 weekly using our full automation service.
                    </p>

                    <h3 className="my-6 text-xl text-gray-700 dark:text-white ">
                        Features
                    </h3>
                    <ul className="text-gray-600 dark:text-gray-300 list-disc mx-8 ">
                        <li>1,200 ready to purchase email leads!!!</li>
                        <li>365 days autoresponder Messages (100 every three months)</li>
                        <li>Leads Capture Page ( On Request)</li>
                        <li>Free Website + Hosting of your choice ( IPS, Legacy, Lepo, Simply Passive, UBC, others)</li>
                        <li>The $900 Legacy Builders Package</li>
                        <li>The $1,200 $600 IPS package</li>
                        <li>The Highest Simply Passive Package</li>
                        <li>The Highest LEPO package</li>
                        <li>The Highest UBC package</li>
                        <li>( Our full automation package is worth about $5,000 usd)</li>
                    </ul>
                    {/* <button
                        onClick={() => {
                            setModalAlert((prev) => {
                                return {
                                    options: {
                                        ...prev.options,
                                        visible: true,
                                        message: "In order to allow efficiency of payments and avoid usual errors, we process payment strictly via our chat terminal, please click on the button below to chat with our agent and make payment, we will be with you in seconds! now",
                                        severity: "info",
                                        actionText: "Pay now",
                                        actionClick: () => {
                                            window.location.replace("https://tawk.to/chat/67202b914304e3196ad9c721/1ibaqkbsu");
                                        },
                                        close: () => {
                                            setModalAlert({
                                                options: {
                                                    visible: false,
                                                    actionClick: () => { },
                                                    severity: "info"
                                                }
                                            })
                                        }
                                    }
                                }
                            })
                        }}
                        className="relative mt-10 flex h-12 w-full items-center justify-center px-8 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                    >
                        <span className="relative text-base font-semibold text-white dark:text-dark"
                        >Buy now</span
                        >
                    </button> */}

                    <a
                        href="https://wise.com/pay/r/-Fk-yQ40fTkoR8o" className="relative mt-10 flex h-12 w-full items-center justify-center px-8 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max">
                        <span className="relative text-base font-semibold text-white dark:text-dark"
                        >Buy now</span
                        >
                    </a>

                    <p className="text-gray-600 dark:text-gray-300 mt-5">
                        <b className="text-orange-700">Note:</b> There is a 10% discount available to you if you have the legacy builders program or any of the digital programs listed in the full automation features. <br />
                        Click <a href="https://wise.com/pay/r/1iiw7P--Kn2DxFU" className="underline decoration-primary">link</a> to use discount.
                    </p>
                </div>
            </ContainerView>


        </main>
    )
}

export default FullAutomationDetailsView